import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import {
  PlayArrow,
  Pause,
  VolumeUp,
  VolumeOff,
  RecordVoiceOver,
  Description,
} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Grid';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import PropTypes from 'prop-types';
import AudioDiscussion from './AudioDiscussion';
import notifSystem from '../../../../front/src/notifSystem';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import { connect } from 'react-redux';
import {
  Chip,
  Divider,
  Stack,
  Tab,
  Tabs,
  Tooltip
} from '@mui/material';
import {
  formatResultToJSON,
  formatTextToHTML
} from '../Stats/Cti/CallTable/functions';
import { getCapitalLetters, getEvaluationByRating } from '../../utils/function';

const mapStateToProps = state => ({
  user: state.users.find(e => e._id === state.userId),
  users: state.users
});

const styles = {
  iconSmall: {
    fontSize: 20,
    color: '#000'
  },
  iconCenter: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 8px'
  },
  back: {
    borderRadius: 30,
    padding: 12
  },
  slider: {
    color: '#000'
  },
  track: {
    height: 3
  },
  thumb: {
    height: 0,
    '&:focus, &:hover, .Mui-active': {
      height: 12,
      width: 12,
      boxShadow: 'inherit',
    },
  },
  volumeThumb: {
    width: 12,
    height: 12,
  },
  blurry: {
    filter: 'blur(0.2rem)',
    userSelect: 'none',
  }
};

const removeListenerCallback = f => f;

class AudioPlayer extends Component {
  state = {
    play: false,
    audio: this.props.url,
    showTranscription: 'showTranscription' in this.props ?
      this.props.showTranscription :
      !!this.props.transcription,
    hide: this.props.hide || false,
    isPlayable: true,
    playDidLoad: false,
    tab: 0,
    duration: this.props.duration ?? 59
  };

  retrieveAudio = () => {
    const {
      hide, forceHide,
      processingTextChatgpt, transcription,
      url,
      showTranscription
    } = this.props;
    this.audio = new Audio(url);
    this.setState({
      audio: url,
      showTranscription: 'showTranscription' in this.props ?
        showTranscription :
        !!transcription,
      tab: transcription &&
        !hide &&
        !forceHide &&
        processingTextChatgpt ? 1 : 0
    });
    if (this.audio) {
      this.audio.addEventListener('canplay',
        () => this.setState({ isPlayable: true }));
      this.autoPlay();
      this.audio.addEventListener('ended',
        () => this.setState({ play: false }));
      this.audio.addEventListener('loadeddata', () =>
        this.setState({
          volume: this.audio.volume,
          duration: this.audio.duration
        }));
      this.audio.addEventListener('durationchange', () => {
        if (this.audio.duration !== Infinity) {
          this.setState({
            duration: this.audio.duration
          });
        }
      });
      this.audio.addEventListener('timeupdate', () => this.setState({
        currentTime: this.audio.currentTime
      }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.url !== this.props.url) {
      this.audio?.pause();
      this.setState({ play: false, isPlayable: false, currentTime: 0 });
      this.retrieveAudio();
    }
    if (prevProps.transcription !== this.props.transcription) {
      this.setState({ showTranscription: !!this.props.transcription });
    }
  }

  copieLink = (e, link) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(link);
      notifSystem.success('Message', 'Le lien a bien été copié');
    }
    e.stopPropagation();
  };

  componentWillUnmount() {
    if (this.audio) {
      if (this.state.playDidLoad) {
        this.audio.pause();
      }
      // remove the Listeners
      this.audio.removeEventListener('canplay', removeListenerCallback);
      this.audio.removeEventListener('ended', removeListenerCallback);
      this.audio.removeEventListener('loadeddata', removeListenerCallback);
      this.audio.removeEventListener('durationchange', removeListenerCallback);
      this.audio.removeEventListener('timeupdate', removeListenerCallback);
    }
  }

  formatDuration = (duration) =>
    duration && dayjs.utc(duration * 1000).isValid()
      ? dayjs.utc(duration * 1000).format('m:ss')
      : '0:00' || '0:00'


  play = () => {
    this.audio.play().then(() => {
      this.setState({
        playDidLoad: true,
      });
    }).catch(() => {
      this.setState({
        play: false, isPlayable: false, currentTime: 0, duration: 0
      })
    });
  }

  autoPlay = () => {
    if (this.props.autoPlay) {
      this.setState({ play: true }, () => {
        this.play();
        if (this.props.cb) {
          this.props.cb();
        }
      });
    }
  }

  togglePlay = (event) => {
    const { dontPlay } = this.props;
    if (!dontPlay) {
      event.stopPropagation();
      if (!this.audio) {
        this.retrieveAudio();
      }
      this.setState({ play: !this.state.play }, () => {
        if (this.state.play) {
          if (this.props.cb) {
            this.props.cb();
          }
          this.play();
        } else {
          this.audio.pause();
        }
      });
    } else if (this.props.cb) {
      this.props.cb();
    }
  };

  mute = (event) => {
    event.stopPropagation();
    const { volume } = this.state;
    return volume
      ? this.setState({ volume: 0 }, () => {
        this.audio.volume = 0;
      })
      : this.setState({ volume: 1 }, () => {
        this.audio.volume = 1;
      });
  }

  handleChangeVolume = (event, newValue) => {
    this.setState({ volume: newValue / 100 }, () => {
      this.audio.volume = newValue / 100;
    });
  };

  handleChangeTime = (event, newValue) => {
    this.setState({
      currentTime: ((newValue * this.state.duration) / 100)
    }, () => {
      this.audio.currentTime = ((newValue * this.state.duration) / 100);
    });
  };

  show = () => this.setState({
    showTranscription: !this.state.showTranscription
  })

  handleChangeTab = (event, tab) => this.setState({ tab });

  render() {
    const {
      classes,
      transcription,
      noPadding,
      noCopyIcon,
      forceHide,
      processingTextChatgpt,
      processingTextChatgptQuality,
      processingTextEngineQuality,
      transcriptorEngine,
      processingTextEngine,
      user
    } = this.props;
    const {
      volume,
      currentTime,
      duration,
      showTranscription,
      mouseOn,
      hide,
      audio,
      isPlayable,
      tab
    } = this.state;
    let sliderGridXs = 7;
    if (transcription) {
      sliderGridXs = sliderGridXs - 1;
    }
    if (noCopyIcon) {
      sliderGridXs = sliderGridXs + 1;
    }
    return (<Grid container className={noPadding ? '' : classes.back}>
      <Grid item className={classes.iconCenter} xs={1}>
        <IconButton size="small"
          disabled={!this.state.duration || !audio || !isPlayable}
          onClick={this.togglePlay}
          children={this.state.play
            ? <Pause className={classes.iconSmall} />
            : <PlayArrow className={classes.iconSmall} />}
        />
      </Grid>
      <Grid item className={classes.iconCenter} xs={3}>
        <Typography children={`${this.formatDuration(this.state.currentTime)
        } / ${this.formatDuration(this.state.duration)}`}
        />
      </Grid>
      <Grid container item xs={sliderGridXs}
        onClick={(event) => event.stopPropagation()}
      >
        <Grid item xs={mouseOn ? 4 : 10} className={classes.iconCenter}>
          <Slider
            disabled={
              (!this.state.duration || this.state.duration === Infinity)
              || !audio
              || !isPlayable
            }
            classes={{ root: classes.slider, thumb: classes.thumb }}
            value={currentTime === 0 ? 0 : currentTime * 100 / duration || 0}
            onChange={this.handleChangeTime}
            aria-labelledby="continuous-slider"
          />
        </Grid>
        <Grid container item xs={mouseOn ? 8 : 1}
          onMouseLeave={() => this.setState({ mouseOn: false })}
          onMouseEnter={() => this.setState({ mouseOn: true })}
        >
          {mouseOn && <Grid className={classes.iconCenter} item xs={9}>
            <Slider
              disabled={!this.state.duration || !audio || !isPlayable}
              classes={{ thumb: classes.volumeThumb, track: classes.track }}
              value={volume === 0 ? 0 : volume * 100 || 100}
              onChange={this.handleChangeVolume}
              aria-labelledby="continuous-slider"
            />
          </Grid>}
          <Grid item xs={1} className={classes.iconCenter}>
            <IconButton onClick={this.mute} size="small" >
              {volume
                ? <VolumeUp className={classes.iconSmall} />
                : <VolumeOff className={classes.iconSmall} />}
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      {transcription && <Grid item className={classes.iconCenter} xs={1}>
        <IconButton onClick={this.show} size="small">
          <RecordVoiceOver className={classes.iconSmall} />
        </IconButton>
      </Grid>}
      {!noCopyIcon ? <Grid item className={classes.iconCenter} xs={1}>
        <IconButton
          size="small"
          onClick={(e) => this.copieLink(e, this.props.url)}
          children={<Description className={classes.iconSmall} />}
        />
      </Grid> : ''}
      {showTranscription && !hide && !forceHide && <Grid item xs={12}>
        <Tabs
          value={tab}
          onChange={this.handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab
            title={transcriptorEngine || ''}
            label={`Transcription (${transcriptorEngine ?
              getCapitalLetters(transcriptorEngine) : 'DG'})`}
          />
          <Tab
            title={processingTextEngine || ''}
            label={`Analyse (${processingTextEngine ?
              processingTextEngine : 'CHATGPT'})`}
            disabled={!processingTextChatgpt}
          />
          {
            permit(user, { key: 'selectReportManager' }) && <Tab
              title={processingTextEngineQuality || ''}
              label={`Contrôle Qualité(${processingTextEngineQuality ?
                processingTextEngineQuality : 'CHATGPT'})`}
              disabled={!processingTextChatgptQuality}
            />
          }
        </Tabs>
      </Grid>}
      {transcription && showTranscription && !hide && !forceHide && [
        tab === 0 && <AudioDiscussion transcription={transcription}
          blurry={!permit(user, { key: 'accesCallToAndBackText' })
            && classes.blurry} />,
        tab === 1 &&
        <Grid item xs={12}>{formatTextToHTML(processingTextChatgpt)}</Grid>,
        tab === 2 && <Grid container padding={4}>
          <Stack
            spacing
            divider={<Divider flexItem />}
          >
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Typography children='Tâches' variant='h6' />
              <Typography children='Evaluations' variant='h6' />
            </Stack>
            {
              formatResultToJSON(processingTextChatgptQuality)
                .content?.map((item, i) => (
                  <Stack
                    key={i}
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                  >
                    <Typography children={item.task} />
                    <QualityControlEval item={item} />
                  </Stack>
                ))
            }
          </Stack>
        </Grid>
      ]}
    </Grid>
    );
  }
}

/**
 * @param {{item: {comment: string, rate?: number, rating?: number}}} props
 */
function QualityControlEval({ item }) {
  const evaluation = getEvaluationByRating(item.rate);
  return <Tooltip title={item.comment || 'Pas de commentaire.'}>
    <Chip
      label={evaluation.eval}
      color={evaluation.color}
      style={{
        padding: '3px'
      }}
    />
  </Tooltip>
}

AudioPlayer.PropTypes = {
  url: PropTypes.string.isRequired,
  transcription: PropTypes.string
};

export default connect(mapStateToProps)(withStyles(AudioPlayer, styles));
